





































































import { Prop, Component, Vue } from 'vue-property-decorator'
import { VeeValidateObserverRef } from '@/models/Common/Validation'

@Component
export default class ModalAddFolder extends Vue {
  $refs!: {
    newName: VeeValidateObserverRef
  }
  @Prop() message!: string
  private newName: string = ''
  private ableClose: boolean = false

  handlePreventEvent(event: any) {
    if (!this.ableClose && this.newName) {
      event.preventDefault()
      this.confirmHideModal()
    }
  }

  confirmHideModal() {
    if (this.newName) {
      this.$bvModal.show('add-folder-modal-confirm')
    } else {
      this.hideModal()
    }
  }

  async hideModal() {
    await this.handleHide()
    this.ableClose = false
  }

  async handleHide() {
    this.ableClose = true
    this.newName = ''
    this.$bvModal.hide('add-folder')
  }

  onSubmit() {
    this.$emit('submit', this.newName)
    this.newName = ''
    this.$refs.newName.reset()
    this.hideModal()
  }
}
